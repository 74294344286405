import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    loadingAnimation: {
        maxWidth: '84px',
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
    },

});

export default styles;

export const useStyles = makeStyles(styles);
