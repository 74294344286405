import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { observer } from 'mobx-react';
import {
  Grid,
  InputAdornment,
  Fade,
  Box,
  TextField as Input,
  Link,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
// import { ErrorListings } from '../../store/models/error/error_map';

// styles
import { useStyles } from './login.styles';

// import EcalLogoImage from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';

// logo
// import logo from "./logo.svg";
import google from '../../commons/images/google.svg';

// components
import LoadingAnimation from '../../components/LoadingAnimation';
import { Button, Typography } from '../../components/Wrappers/Wrappers';

import { useUserStore, useEnvStore } from '../../store/models';
import useNavigation from '../hooks/useNavigation';
import useOpenSignIn from '../hooks/useOpenSignin';
import useEnvHistory from '../hooks/useEnvHistory';
import LoginOTP from './loginOTP';
import useLoginError from './useLoginError';
import { SignInSignUpState, AuthType } from '../../utils/constants';
import { intercomShutdown } from '../../utils/intercom';
import LoginForgot from './forgot';
import useModuleErrorDialog from '../../store/models/error/useModuleErrorDialog';
import useGenericErrorDialog from '../../store/models/error/useGenericErrorDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import useWhiteLabel from '../../store/models/styles/useWhiteLabel';
import useStateRef from '../hooks/useStateRef';

// import { removeSubdomainTemporarily } from '../../utils';

function Login (props) {
  const {
    gotoVerifyAccount,
    gotoSignUp,
  } = useNavigation();

  const {
    whiteLabelSettings,
  } = useWhiteLabel();

  const classes = useStyles({ whiteLabelSettings });
  const userStore = useUserStore();
  const envStore = useEnvStore();
  const captchaRef = React.createRef();

  useEnvHistory();
  const [hasCaptcha, setHasCaptcha] = useState(!(envStore.Environment === 'stage'));
  const [showOTP, setShowOTP] = useState(false);

  const {
    signInWithGoogle,
  } = useOpenSignIn({
      whiteLabelSettings,
      autoLogin: envStore?.settings?.autologin,
    });

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [error] = useState(null);
  const [isForgot, setIsForgot] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginInput, setLoginInput, loginInputRef] = useStateRef({
    /*
      email: 'jerry.cross.001@gmail.com',
      email: 'mary.rose.001@gmail.com',
       email: 'jack.happy.writing@gmail.com',
      password: 'Password@143',
    */
   /*
    email: 'jack.happy.writing@gmail.com',
    password: 'Password@143',
    */
  });

  const {
    loginError,
    loginDisabled,
    errors,
    loginDisabledRef,
  } = useLoginError({ loginInput, setIsLoading });

  function onCloseError () {
    console.log('Errors -> ', errors);
    if (errors.errCode === 'UNVERIFIED ACCOUNT') {
      gotoVerifyAccount();
    }
    setIsLoading(false);
  }

  const {
    renderErrorDialog,
} = useModuleErrorDialog({
    errors,
    onCloseError,
});

  const {
    renderErrorDialog: renderGenericeErrorDialog,
  } = useGenericErrorDialog();

  useEffect(() => {
    const otpOpenState = [
      SignInSignUpState.OTP_CONFIRMATION,
      SignInSignUpState.OTP_RESEND,
      SignInSignUpState.OTP_RESENDING,
    ];

    if (otpOpenState.includes(envStore.signInSignUpState.status)) {
      setShowOTP(true);
    } else {
      setShowOTP(false);
    }
  }, [envStore.signInSignUpState.status]);

  useEffect(() => {
    setIsLoading(false);
    /*
     * disable capatcha the when password failed to make the loading faster.
     * because it proves that the one using is a human and trying to relogin again.
     */
    if (userStore.loginAttempt) {
      setHasCaptcha(false);
    }
  }, [loginError]);

  useEffect(() => {
    envStore.log('Has Captcha ?: ', hasCaptcha);
  }, [hasCaptcha]);

  async function login () {
    setIsLoading(true);
    if (hasCaptcha) {
        const token = await captchaRef.current.executeAsync();
        console.log('Captcha Token: ', token);
        setHasCaptcha(false);
    }

    userStore.login({
      username: loginInput.email,
      password: loginInput.password,
      setupForPlan: false,
    });
  }

  async function loginOnKeyDown () {
    if (loginDisabledRef.current === false) {
      setIsLoading(true);
      const input = loginInputRef.current;
      userStore.login({
        username: input.email,
        password: input.password,
        setupForPlan: false,
      });
    }
  }

  const loginOnKey = (e) => {
    if (e.code === 'Enter' ||
      e.code === 'NumpadEnter' ||
      e.keyCode === 13) {
      loginOnKeyDown();
    }
  };

  useEffect(() => {
    envStore.signInSignUpState.status = SignInSignUpState.SIGNED_OUT;
    intercomShutdown();
    setShowOTP(false);
    if (envStore.mode === 'test') {
      setHasCaptcha(false);
    } else {
      setHasCaptcha(true);
    }
    window.addEventListener('keydown', loginOnKey);
    return () => {
      setShowOTP(false);
      window.removeEventListener('keydown', loginOnKey);
    };
  }, []); // eslint-disable-line

  function renderOpenSignin () {
    function signInLabel () {
      if (envStore?.settings?.autologin) {
        return (<React.Fragment>Signing in with</React.Fragment>);
      }
      return <React.Fragment>{whiteLabelSettings?.signInLabel}</React.Fragment>;
    }
    return (
      <div className={classNames([classes.formContainer, classes.formCenter])}>
        <div className={classes.form}>
            <React.Fragment>
              <Grid item container className={classes.logoImageContainer}>
                <div id='logoImage' className={classes.logoImage}></div>
              </Grid>

              <Button
                disabled={envStore?.settings?.autologin}
                size="large"
                className={classes.googleButton}
                onClick={() => {
                  signInWithGoogle();
                }}
              >
                <span >
                  { signInLabel()}
                </span>
                { whiteLabelSettings?.signInImage && (
                    <img src={whiteLabelSettings?.signInImage || ''} className={classes.openIdLogo} />
                )}

                { envStore?.settings?.autologin && (
                  <LoadingAnimation />
                )}

              </Button>
          </React.Fragment>
        </div>
      </div>
    );
  }

  function renderForm () {
    if (whiteLabelSettings.labelName !== 'ecal') {
      return renderOpenSignin();
    }

    if (isForgot) {
      return (
        <LoginForgot
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          onBackToLogin={() => {
            setIsForgot(false);
          }}
        />
      );
    }

    /* Default Login Form */
    return (
      <div className={classes.formContainer}>
        <div className={classes.form}>
            <React.Fragment>
              <Grid item container className={classes.logoImageContainer}>
                <div id='logoImage' className={classes.logoImage}></div>
              </Grid>

              <Grid className={classes.headerSpace}>
                <Typography variant={'body1'} className={classes.headerTextTitle}>
                  Log in to ECAL Publisher Admin
                </Typography>
                <Typography variant={'body2'} className={classes.headerText}>
                </Typography>
              </Grid>

              <Button
                size="large"
                className={classes.googleButton}
                onClick={() => {
                  signInWithGoogle();
                }}
              >
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
              </Button>
              <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div>

              <Typography variant={'body1'} className={classes.welcomeText}>
                Welcome back! Please log in to your account.
              </Typography>
              <Fade
                in={error}
                style={
                  !error ? { display: 'none' } : { display: 'inline-block' }
                }
              >
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password :(
                </Typography>
              </Fade>
              <Input
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input,
                  },
                }}
                value={loginInput.email}
                onChange={e => {
                  setLoginInput({
                    ...loginInput,
                    email: e.target.value,
                  });
                }}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
                error={ loginError.email }
                helperText={
                  loginError.email || 'Enter email address.'
                }
              />
              <Input
                id="password"
                type={showPassword ? 'Text' : 'Password'}
                InputProps={{
                  classes: {
                    underline: classes.InputUnderline,
                    input: classes.Input,
                  },

                  endAdornment: (
                    <InputAdornment position="start">
                          <Box onClick={() => {
                              setShowPassword(!showPassword);
                          }}>
                            <FontAwesomeIcon icon={ showPassword ? faEye : faEyeSlash } />
                          </Box>
                    </InputAdornment>
                  ),
                }}
                value={loginInput.password}
                onChange={e => {
                  console.log('Password value to set: ', e.target.value);
                  setLoginInput({
                    ...loginInput,
                    password: e.target.value,
                  });
                }}
                margin="normal"
                placeholder="Password"
                fullWidth
                error={ loginError.password }
                helperText={
                  loginError.password || 'Enter password.'
                }
              />
              <div className={classes.formButtons}>
                {isLoading
? (
                  <Box className={classes.loadingAnimationBox}><LoadingAnimation /></Box>
                )
: (
                  <Button
                    disabled={loginDisabled}
                    onClick={async () => login()}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  onClick={() => setIsForgot(!isForgot)}
                  className={classes.forgetButton}
                >
                  Forgot Password?
                </Button>
              </div>
              <div className={classes.signUpSentence}>
                <Typography className={classes.signUpText}>
                  Don&apos;t have an account?
                </Typography>
                <Link
                  onClick={() => gotoSignUp()}
                  className={classes.signUpLink}
                >
                  SIGN UP
                </Link>
              </div>
              { hasCaptcha && (
                <div>
                  <ReCAPTCHA
                        sitekey="6Lcgdm0bAAAAAI0s80YcZMpzg7iyUVggfPZ15tno"
                        ref={captchaRef}
                        size="invisible"
                        onChange={(data) => {
                            console.log('Re captcha Complete: ', data);
                        }}
                  />
                </div>
              )}
            </React.Fragment>

        </div>
        <Typography variant={'body2'} color="primary" className={classes.copyright}>
          { whiteLabelSettings.copyrightText }
        </Typography>
      </div>
    );
  }

  return (
    <React.Fragment>
    <Grid container className={classes.container}>
      <div className={classes.formDividerContainer}></div>
      <div className={classes.logotypeContainer}>
        <img src={whiteLabelSettings.background} alt="logo" className={classes.logotypeImage} />
      </div>
      {renderForm()}
    </Grid>

    <LoginOTP
      open={showOTP}
      onClose={() => setShowOTP(false)}
      qrCode={userStore.qrCode}
      resendOTP={envStore.signInSignUpState.status === SignInSignUpState.OTP_RESEND}

      onVerify={(otp) => {
        if (envStore.signInSignUpState.authType === AuthType.VERIFY_OTP) {
          userStore.verifyOTP({ otp });
        } else {
          userStore.confirmSignIn({ code: otp });
        }
      }}

      onResendOTP={() => {
        userStore.resendForOTP();
      }}

    />
    { renderErrorDialog() }
    { renderGenericeErrorDialog() }
    </React.Fragment>
  );
}

/*
SELECT organisation.objective as objective,organisation.name As orgName,user.firstName,user.status,user.organisationId As orgId,user.id,user.lastName,timezone.name as timezone,organisation.industry as industry FROM user INNER JOIN organisation ON user.organisationId = organisation.id  INNER JOIN timezone ON user.timezoneId = timezone.id  WHERE user.id = ?
SELECT  user.lastName,user.id,user.openTop As openTop,organisation.name As name,user.firstName,user.organisationId As orgId, user.email, user.status, user.subscription_plan_id,organisation.industry as industry,organisation.objective as objective  FROM user  INNER JOIN organisation ON user.organisationId = organisation INNER JOIN timezone ON user.timezoneId = timezone.id WHERE organisation.name LIKE '%jack%' OR user.firstName LIKE '%jack%' OR user.lastName LIKE '%jack%' OR user.email = ''
*/

export default withRouter(observer(Login));
