import { Auth } from 'aws-amplify';
// for extra security, in case user got idle for 30 or 60 minutes, and amplify failed to close session.
//                             300000

export const TIME_5_MINUTES = 300000;
export const TIME_30_MINUTES = 1800000;
export const TIME_60_MINUTES = 3600000;
/*
 * This SESSION_LIMIT is used to measure how long the idle time of users before the app should auto THROW Session Error
 * NOTE: Only used in UI side, it is not involve in Cognito-Amplify Sessions.
 * Cognito-Amplify Sessions are totally independent. Timing is managed by Amplify AWS library for Cognito
 */
export const SESSION_LIMIT = TIME_60_MINUTES; // SET BACK TO 1 Hour

/*
 * RefreshSession function are called to check if Cognito-Amplify Sessions is expired or not.
 * 2 places where it is checked are:
 * 1 ) On Mouse clicks within the app
 * 2 ) Before API calls
 */

export async function refreshSession () {
    // console.log('refreshSession() refreshSession cognito session()');
    let session;
    try {
        session = await Auth.currentSession();
        if (session && session.idToken) {
            await Auth.currentAuthenticatedUser().catch(err => {
                console.log('refreshSession() will dispatch SessionError from refresh session because of SESSION error -> ', err);
                this.env.errorStore.dispatchSessionError();
            });
        }
    } catch (err) {
        console.log('refreshSession() will dispatch SessionError from refresh session because of GENERAL error -> ', err);
        this.env.errorStore.dispatchSessionError();
    }
}

export function expireSession () {
    this.env.setPageInfo('session-expiration', { expired: true });
}

export function verifySessionExpired () {
    this.sessionExpired = this.env.getPageInfo('session-expiration');
    // this.sessionExpired = false;
    if (this.sessionExpired && this.sessionExpired.expired === true) {
        this.env.errorStore.dispatchSessionError();
        return true;
    }
    return false;
}

/*
 * Adjust the expiration time on the sessionLimit
 */
export function expireWhenIdle () {
    clearTimeout(window.idleTimeout);
    const self = this;
    const env = this.env;
    function forceExpire () {
        self.expireSession();
        env.errorStore.dispatchSessionError();
    }
    // console.log('expireWhenIdle() ',  this.sessionLimit);
    window.idleTimeout = setTimeout(forceExpire, this.sessionLimit);
}
