
import { makeStyles } from '@material-ui/core/styles';
import EcalLogo from '../../commons/images/brandImage/ECAL_Logo.svg';

const styles = (theme) => ({

    container: {
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
      },

      logotypeContainer: {
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: '#12012C',
        width: '60%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        [theme.breakpoints.down('md')]: {
          width: '50%',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },

      logotypeImage: {
        width: '100%',
        // height: '100vh',
      },
      logotypeText: {
        color: 'white',
        fontWeight: 500,
        fontSize: 84,
        [theme.breakpoints.down('md')]: {
          fontSize: 48,
        },
      },
      welcomeText: {
        fontWeight: '300!important',
      },
      customFormContainer: {
        width: '40%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          // width: '50%',
          // overflow: 'scroll',
        },
      },

      formContainer: {
        width: '40%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
          width: '50%',
          overflow: 'visible',
        },
      },
      form: {
        width: 410,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
          width: 320,
        },
      },

      tab: {
        fontWeight: 400,
        fontSize: 18,
      },
      greeting: {
        fontWeight: 500,
        textAlign: 'center',
        marginTop: theme.spacing(4),
      },
      subGreeting: {
        fontWeight: 500,
        textAlign: 'center',
        marginTop: theme.spacing(2),
      },
      googleButton: {
        marginTop: theme.spacing(2),
        boxShadow: theme.customShadows.widget,
        backgroundColor: 'white',
        width: '100%',
        textTransform: 'none',
      },
      googleButtonCreating: {
        marginTop: 0,
      },
      googleIcon: {
        width: 30,
        marginRight: theme.spacing(2),
      },
      creatingButtonContainer: {
        marginTop: theme.spacing(2.5),
        height: 46,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      createAccountButton: {
        height: 46,
        textTransform: 'none',
      },
      formDividerContainer: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
      },
      formDividerWord: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
      formDivider: {
        flexGrow: 1,
        height: 1,
        backgroundColor: theme.palette.text.hint,
      },
      errorMessage: {
        textAlign: 'center',
      },
      // lineUnderLogo: {
      //   '&:after': {
      //     content: '',
      //     width: '30px',
      //     height: '6px',
      //     // background: var(--logo_underline_color),
      //     display: 'block',
      //     margin: '1em auto',
      //   },
      // },
      textFieldUnderline: {
        '&:before': {
          borderBottomColor: theme.palette.primary.light,
        },
        '&:after': {
          borderBottomColor: theme.palette.primary.main,
        },
        '&:hover:before': {
          borderBottomColor: `${theme.palette.primary.light} !important`,
        },
      },
      textField: {
        borderBottomColor: theme.palette.background.light,
      },
      formButtons: {
        width: '100%',
        marginTop: theme.spacing(4),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },

      forgetButton: {
        textTransform: 'none',
        fontWeight: 400,
      },

      linkButton: {
        textTransform: 'none',
        fontWeight: 400,
        '&:hover': {
          textDecoration: 'underline',
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
      },

      // loginLoader: {
      //   marginLeft: theme.spacing(4),
      // },
      copyright: {
        marginTop: '32px',
        whiteSpace: 'nowrap',
        // position: 'fixed',
        bottom: '0',
        opacity: '0.7',

        [theme.breakpoints.down('sm')]: {
          fontSize: '0.75rem !important',
        },
      },
      logInSentence: {
        marginTop: '20px',
        fontSize: '12px',
      },
      logInText: {
        display: 'inline',
        fontSize: '12px!important',
        fontWeight: '300!important',
      },
      logInLink: {
        marginLeft: '5px',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: '400',
      },

      logoImageContainer: ({ whiteLabelSettings }) => {
        function padding () {
          if (whiteLabelSettings &&
              whiteLabelSettings?.labelName !== 'ecal') {
            return '30px 0px 0px 0px';
          }
          return '30px 20px 0px 0px';
        };
        return {
          width: '100%',
          height: '90px',
          display: 'flex',
          justifyContent: 'center',
          padding: padding(),
        };
      },

      logoImage: ({ whiteLabelSettings }) => {
        function logo () {
          if (whiteLabelSettings) {
            return whiteLabelSettings.logo;
          }
          return EcalLogo;
        }

        return {
          backgroundSize: '150px',
          backgroundImage: `url(${logo()})`,
          width: '150px',
          height: '30px',
        };
      },

      formBodyHeadline: {
        paddingBottom: 10,
      },

      termsBox: {
        width: '100%',
        marginBottom: '32px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      },

      termsCheckRoot: {
        marginRight: '8px',
      },

      headerSpace: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      },

      headerTextTitle: {
        maxWidth: '320px',
        fontSize: '16px !important',
        fontWeight: 'bold !important',
        textAlign: 'center',
        marginBottom: '8px',
      },

      headerText: {
        maxWidth: '320px',
        fontSize: '13px !important',
        textAlign: 'center',
        marginBottom: '16px',
      },
});

export default styles;
export const useStyles = makeStyles(styles);
