import React, { useState, useEffect } from 'react';
import ImageUploading from 'react-images-uploading';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';

import PropTypes from 'prop-types';
import {
    PublishOutlined as CreateIcon,
    DeleteOutline as DeleteIcon,
    UndoRounded as UndoIcon,
} from '@material-ui/icons';

import {
    Grid,
    Box,
    IconButton,
} from '@material-ui/core';
/*
import {
    Button,
} from '../../components/Wrappers/Wrappers';
*/
import useStyles from './uploader.styles';

import { DEFAULT_IMAGE } from './DefaultImage';
import LoadingAnimation from '../../components/LoadingAnimation';
const defaultImageData = [{ data_url: DEFAULT_IMAGE }];

const DEFAULT_WIDTH = '100px';
const DEFAULT_HEIGHT = '100px';
function ImageUploaderComp (props) {
    const {
        onImageSelected,
        onImageUndo,
        onImageDelete,
        imageData,
        createTooltip,
        deleteTooltip,
        hasUpload,
        hasDelete,
        imageWidth,
        imageHeight,
        hasUndo,
        undoTooltip,
        layoutDesign,
        isUploading,
        imageType,
        dataIntercomTarget,
        imageDataIntercomTarget,
        isChild,
    } = props;
    const [images, setImages] = useState(defaultImageData);

    const [imageSize, setImageSize] = useState({
        width: imageWidth,
        height: imageHeight,
    });

    const classes = useStyles({ imageSize, imageType });

    useEffect(() => {
        setImages(defaultImageData);
    }, []);

    useEffect(() => {
        setImageSize({
            width: imageWidth,
            height: imageHeight,
        });
    }, [imageWidth, imageHeight]);

    useEffect(() => {
        if (imageData.length > 0) {
            setImages(imageData);
        }
    }, [imageData]);

    // const onDrop = (pictureFiles, pictureDataURLs) => {
    //     setImages(pictureDataURLs);
    //     if (typeof onImageSelected === 'function') {
    //         onImageSelected(pictureFiles);
    //     }
    // };

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        if (imageList.length === 0) {
            const defaultImage = [{ data_url: DEFAULT_IMAGE }];
            setImages(defaultImage);
            return;
        };
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
        if (typeof onImageSelected === 'function') {
            onImageSelected(imageList);
        }
    };

    const customClass = () => {
        if (layoutDesign === 'column') {
            return classes.columnLayout;
        } else if (layoutDesign === 'row') {
            return classes.rowLayout;
        } else if (layoutDesign === 'spaceBetween') {
            return classes.spaceBetweenLayout;
        }
    };

    return (
        <Grid container className={classes.imageDropContainer}
        >
            <ImageUploading
                // withIcon={false}
                // withPreview={true}
                // label="upload image"
                // buttonText="Upload Images"
                // onChange={onChange}
                // imgExtension={['.jpg', '.gif', '.png', '.gif', '.svg']}
                // maxFileSize={1048576}
                // fileSizeError=" file size is too big"
                // multiple
                value={images}
                onChange={onChange}
                // maxNumber={60}
                dataURLKey="data_url"
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageRemove,
                    onImageUpdate,
                    isDragging,
                    dragProps,
                }) => (
                    <div
                        data-intercom-target={imageDataIntercomTarget}
                        className= {classNames([
                            classes.input,
                            customClass(),
                        ])}>
                        {imageList.map((image, index) => (
                            <div key={index} className={classes.imageThumbnail}>

                                <img
                                    src={image.data_url}
                                    className={classes.image}
                                    alt=""
                                    onClick={() => onImageUpdate(index)}
                                    />

                                { isUploading && (
                                    <Grid className={classes.uploadProgressBox}>
                                        <LoadingAnimation />
                                    </Grid>
                                )}

                            </div>
                        ))}

                        { (hasUpload || hasDelete || hasUndo) && (
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                            >
                                {hasUpload && (
                                    <Tooltip title={createTooltip}>
                                        <IconButton
                                            id="upload-button"
                                            data-ecal-id="upload-button"
                                            data-file="image-file"
                                            disabled={isChild ? true : isUploading}
                                            color={'primary'}
                                            onClick={onImageUpload}
                                            className={classes.iconButton}
                                            data-intercom-target={dataIntercomTarget}
                                        >
                                            <CreateIcon
                                                className={classes.deleteIcon}
                                                fontSize={'medium'} />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {hasDelete && (
                                    <Tooltip title={deleteTooltip}>
                                        <IconButton
                                            disabled={isChild ? true : isUploading}
                                            color={'primary'}
                                            onClick={() => {
                                                onImageRemove();
                                                onImageDelete();
                                            }}
                                            className={classes.iconButton}
                                        >
                                            <DeleteIcon className={classes.deleteIcon} fontSize={'medium'} />
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {hasUndo && (
                                    <Tooltip title={undoTooltip}>
                                        <IconButton
                                             disabled={isUploading}
                                            variant={'outline'}
                                            color={'primary'}
                                            onClick={() => {
                                                onImageUndo();
                                            }}
                                            className={classes.iconButton}
                                        >
                                            <UndoIcon className={classes.deleteIcon} fontSize={'medium'} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        )}
                    </div>
                )}
            </ImageUploading>

        </Grid>
    );
};

ImageUploaderComp.propTypes = {
    onImageSelected: PropTypes.func,
    onImageUndo: PropTypes.func,
    imageData: PropTypes.array,
    createTooltip: PropTypes.string,
    deleteTooltip: PropTypes.string,
    undoTooltip: PropTypes.string,
    createLabel: PropTypes.string,
    deleteLabel: PropTypes.string,
    layoutDesign: PropTypes.string,
    hasUpload: PropTypes.bool,
    hasDelete: PropTypes.bool,
    hasUndo: PropTypes.bool,
    imageWidth: PropTypes.any,
    imageHeight: PropTypes.any,
    isUploading: PropTypes.bool,
    imageType: PropTypes.string,
};

ImageUploaderComp.defaultProps = {
    onImageSelected: () => {},
    onImageUndo: () => {},
    onImageDelete: () => {},
    imageData: [],
    createTooltip: 'Upload',
    deleteTooltip: 'Delete',
    undoTooltip: 'Undo',
    layoutDesign: 'column',
    hasUpload: true,
    hasDelete: true,
    hasUndo: false,
    createLabel: 'Change',
    deleteLabel: 'Delete',
    imageWidth: DEFAULT_WIDTH,
    imageHeight: DEFAULT_HEIGHT,
    isUploading: false,
    imageType: 'square',
    dataIntercomTarget: '',
    imageDataIntercomTarget: '',
};

export default ImageUploaderComp;
