import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { useStyles } from './signUp.styles';
import Timezones from '../../commons/util/Timezone.json';
import Mapping from '../../commons/util/IndustryObjectiveMapping.js';
import Industries from '../../commons/util/IndustriesList.js';
import Objectives from '../../commons/util/Objectives.js';
import Checkbox from '../../commons/Checkbox';
import {
    InputAdornment,
    Grid,
    Box,
    Link,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import Select from '../../commons/select/Select';
import { Button, Typography } from '../../components/Wrappers';
import ButtonProgress from '../../commons/ButtonProgress';
import useSignUpErrorHook from './useSignUpErrorHook';
import useSignupInputLimits from './useSignupInputLimits';
// import Widget from '../../components/Widget';
import useFeatures from '../hooks/useFeatures';
import { useUserStore, useEnvStore } from '../../store/models';
import useSignupProgress from './useSignupProgress';

function getStepContent (step, isSetup = false, isAWSMarketPlace = false) {
    if (isSetup) {
        return 'Update your details';
    }

    switch (step) {
        case 0:
            if (isAWSMarketPlace) {
                return 'Create an account';
            }
            return 'Create an account (30 days free)';
        case 1:
            return 'Let\'s do this!';
        default:
            return 'Create an account';
    }
}

const I_ACCEPT = 'I accept ECAL\'s ';

const SignUpForm = (props) => {
    const {
        onSignUp,
        isSetup,
        setupData = {},
        showLoadingCue = false,
        onInsertLead,
    } = props;

    const userStore = useUserStore();
    const envStore = useEnvStore();

    const {
        features,
    } = useFeatures();

    const [showPassword, setShowPassword] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [isLoading, setIsLoading] = useState(showLoadingCue);
    const [termsChecked, setTermsChecked] = useState(false);

    const {
        signupProgressText,
        progressComplete,
        progressStarted,
    } = useSignupProgress();

    const [newUser, setNewUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: null,
        publisherName: isSetup ? setupData.publisherName : '',
        phoneNumber: '',
        industry: isSetup && setupData.industry ? setupData.industry : '',
        timezone: isSetup && setupData.timezone ? setupData.timezone : '',
      });

      const { signUpError } = useSignUpErrorHook({ signUpInput: newUser });

      function handleChange (e, selectedVal) {
        if (e.target.name === 'industry') {
            getObjectivesList(selectedVal.props.code);
        }

        const updatedData = {
            ...newUser,
            [e.target.name]: e.target.value,
        };
        setNewUser(updatedData);
      }

    function industryCodeFromValue (value) {
        const industryData = Industries.filter((industry) => (value === industry.value))[0];
        return industryData.code;
    }

    const [filteredObjectives, setFilteredObjective] = useState('');

    // const steps = getSteps();
    const classes = useStyles();

    const isStepSkipped = step => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep === 0) {
            setActiveStep(prevActiveStep => prevActiveStep + 1);
            if (typeof props.setFlag === 'function') {
                window.dataLayer.push({ 'event': 'sign_up_next' });
                props.setFlag(false);
            }
            onInsertLead(newUser);
        }
        setSkipped(newSkipped);

        if (activeStep === 1) {
            submitSignUpForm();
        //   doSubmit(null, newUser, history);
        //   sendNotification();
        }
    };

    const submitSignUpForm = () => {
        setIsLoading(true);
        progressStarted();
        if (typeof onSignUp === 'function') {
            window.dataLayer.push({ 'event': 'sign_up_submit' });
            onSignUp(newUser);
        }
    };

    const handleBack = () => {
        props.setFlag(true);
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const getObjectivesList = (IndustryCode = 'Arts00') => {
        const mappingList = Mapping;
        const objectiveCodes = mappingList[0][IndustryCode];
        const objectivesList = Objectives;
        const filteredObjectivesList = objectivesList.filter(f => objectiveCodes.includes(f.code));
        // this.setState({ filteredObjectivesList: filteredObjectives, disableSelectObjList: false });
        setFilteredObjective(filteredObjectivesList);
    };

    async function loadCountry () {
        await userStore.getCountryInfo();
    }

    function getNextLabel () {
        if (setupData.planSettings &&
            setupData.planSettings.plan &&
            features.billingActive) {
            return 'Setup Plan';
        }

        if (isSetup) {
            return 'Update';
        }

        return activeStep === 1 ? 'Finish' : 'Next';
    }

    function getDescriptionHeading () {
        if (isSetup) {
            return 'Please confirm the information below and click UPDATE to save.';
        }
        return 'Give us a little more detail about your events, so we can set-up your admin.';
    }

    useEffect(() => {
        if (signUpError.email) {
            setActiveStep(0);
            setIsLoading(false);
            progressComplete();
        }
    }, [signUpError]);

    useEffect(() => {
        if (!isSetup) {
            return;
        }
        if (setupData) {
            if (isSetup && setupData.industry) {
                const code = industryCodeFromValue(setupData.industry);
                getObjectivesList(code);
            }

            function delayUpdate () {
                setNewUser({
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: null,
                    publisherName: isSetup ? setupData.publisherName : '',
                    phoneNumber: '',
                    industry: isSetup && setupData.industry ? setupData.industry : '',
                    objective: isSetup && setupData.objective ? setupData.objective : '',
                    timezone: isSetup && setupData.timezone ? setupData.timezone : '',
                });
            }
            setTimeout(delayUpdate, 500);
        }
    }, [setupData]);

    useEffect(() => {
        setIsLoading(showLoadingCue);
        if (!showLoadingCue) {
            progressComplete();
        }
    }, [showLoadingCue]);

    useEffect(() => {
        if (userStore.countryInfo) {
            if (!newUser.timezone) {
                setNewUser({
                    ...newUser,
                    timezone: userStore.countryInfo.timezone,
                });
            }
        }
    }, [userStore.countryInfo]);
    useEffect(() => {
        loadCountry();
        if (isSetup) {
            setActiveStep(1);
        }
    }, []);

    const {
        onFirstNameKeyDown,
        onLastNameKeyDown,
        onEmailKeyDown,
        onPhoneKeyDown,
        onPublisherKeyDown,
    } = useSignupInputLimits();

    function submitDisabled () {
        if (!termsChecked) {
            return true;
        }

        if (!newUser.publisherName) {
            return true;
        }
        return signUpError.finishDisabled;
    }

    function isAWSMarketplace () {
        if (envStore.awsMarketplaceToken) {
            return true;
        }
        return false;
    }

    function renderLoadingText () {
        if (isLoading) {
            return (<Typography
                variant={'subtitle'}
                weight={'medium'}
            >
                {signupProgressText}
            </Typography>);
        }
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div>
                    <Grid item justify={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 16 }}
                            >
                                {getStepContent(activeStep, isSetup, isAWSMarketplace())}
                            </Typography>
                            {activeStep === 0
? (
                                <>
                                    <TextField
                                        onKeyDown={onFirstNameKeyDown}
                                        id="first-name"
                                        label="*First Name"
                                        onChange={handleChange}
                                        name="firstName"
                                        value={newUser.firstName || ''}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText="Please enter your first name"
                                    />
                                    <TextField
                                        onKeyDown={onLastNameKeyDown}
                                        id="last-name"
                                        label="*Last Name"
                                        onChange={handleChange}
                                        name="lastName"
                                        value={newUser.lastName || ''}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText={
                                            signUpError.lastName || 'Please enter your last name'
                                        }
                                        error={signUpError.lastName}
                                    />
                                    <TextField
                                        onKeyDown={onEmailKeyDown}
                                        id="email-address"
                                        label="*Email Address"
                                        onChange={handleChange}
                                        value={newUser.email || ''}
                                        name="email"
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        error={signUpError.email}
                                        helperText={
                                            signUpError.email || 'We’ll never share your email with anyone else'
                                        }
                                        type={'email'}
                                    />

                                    <TextField
                                        id="password"
                                        label="*Password"
                                        onChange={handleChange}
                                        name="password"
                                        value={newUser.password || ''}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}

                                        type={showPassword ? 'Text' : 'Password'}
                                        InputProps={{
                                            classes: {
                                                underline: classes.InputUnderline,
                                                input: classes.Input,
                                            },

                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <Box onClick={() => {
                                                        setShowPassword(!showPassword);
                                                    }}>
                                                        <FontAwesomeIcon icon={ showPassword ? faEye : faEyeSlash } />
                                                    </Box>
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={
                                            signUpError.password || 'Enter your password. Min 8 characters long'
                                        }
                                        error={ signUpError.password }
                                    />

                                </>
                            )
: (
                                <>
                                    <div className={classes.formBodyHeadline}>
                                        <Typography variant={'body2'} >
                                            { getDescriptionHeading() }
                                        </Typography>
                                    </div>
                                    <TextField
                                         onKeyDown={onPublisherKeyDown}
                                        id="publisher-name"
                                        label={newUser.publisherName === '' ? '*Publisher Name' : ''}
                                        onChange={handleChange}
                                        name="publisherName"
                                        value={newUser.publisherName || ''}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText={
                                            signUpError.publisherName || 'What\'s your organisation or brand name?'
                                         }
                                         error={signUpError.publisherName}
                                    />
                                    <TextField
                                        onKeyDown={onPhoneKeyDown}
                                        id="phone-number"
                                        label={newUser.phoneNumber === '' ? '*Phone Number' : ''}
                                        onChange={handleChange}
                                        value={newUser.phoneNumber || ''}
                                        name="phoneNumber"
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText={
                                           signUpError.phone || 'What\'s your phone number (just in case)?'
                                        }
                                        error={signUpError.phone}
                                    />
                                    <Select
                                        value={newUser.industry || ''}
                                        hasOutlinedLabel={isEmpty(newUser.industry)}
                                        label= 'industry'
                                        name= 'industry'
                                        id= 'signup-industry'
                                        defaultValueValue=''
                                        labelHeading= '*Industry'
                                        placeHolder= '*Please select industry'
                                        handleChange={handleChange}
                                        dataForDropdown={Industries}
                                        helperText={
                                            signUpError.industry || 'What\'s your industry?'
                                        }
                                        error={signUpError.industry}
                                    />
                                    <Select
                                        value={newUser.objective || ''}
                                        hasOutlinedLabel={isEmpty(newUser.objective)}
                                        defaultValueValue=''
                                        label= 'objective'
                                        name= 'objective'
                                        id= 'signup-objective'
                                        labelHeading= '*Objective'
                                        placeHolder= '*Please select objective'
                                        handleChange={handleChange}
                                        dataForDropdown={filteredObjectives}
                                        helperText={
                                            'What\'s your objective?'
                                        }
                                    />

                                    <Select
                                        value={newUser.timezone || ''}
                                        hasOutlinedLabel={true}
                                        label= 'timezone'
                                        defaultValueValue=''
                                        name= 'timezone'
                                        id= 'signup-timezone'
                                        labelHeading= '*Timezone'
                                        placeHolder= '*Please select timezone'
                                        handleChange={handleChange}
                                        dataForDropdown={Timezones}
                                        marginBottom={12}
                                        helperText={
                                            'What\'s your timezone?'
                                        }
                                    />
                                    <Grid className={classes.termsBox}>
                                        <Checkbox
                                            checked={termsChecked}
                                            defaultChecked={termsChecked}
                                            onChange={e => {
                                                setTermsChecked(!termsChecked);
                                            }}
                                            variant="primary"
                                            label=""
                                            classes={{
                                                root: classes.termsCheckRoot,
                                            }}
                                        />
                                        <span>
                                            <Typography variant="overline">
                                                { I_ACCEPT }
                                                {<Link
                                                    href='http://ecal.com/license-agreement/'
                                                    underline="always"
                                                    target="_blank">
                                                    Terms and Conditions
                                                </Link>}
                                            </Typography>
                                        </span>
                                    </Grid>
                                </>
                            )

}
                            <div>
                                <div>
                                    {activeStep === 0
? (
                                        <Box
                                            display={'flex'}
                                            justifyContent={'flex-end'}
                                        >
                                            <Button
                                                disabled={signUpError.nextDisabled}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    )
: (
                                        <Box
                                            display={'flex'}
                                            justifyContent={isSetup ? 'flex-end' : 'space-between'}
                                        >
                                            {(!isSetup && !isLoading) && (
                                                <Button
                                                    onClick={handleBack}
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                >
                                                    Back
                                                </Button>)
                                            }
                                            { renderLoadingText() }
                                            <ButtonProgress
                                                showProgress={isLoading}
                                                disabled={submitDisabled()}
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                                label={getNextLabel()}
                                            />
                                        </Box>
                                    )}
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

SignUpForm.propTypes = {
    isSetup: PropTypes.bool,
    onInsertLead: PropTypes.bool,
};

SignUpForm.defaultProps = {
    isSetup: false,
    onInsertLead: () => {},
};

export default observer(SignUpForm);
