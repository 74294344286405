import { makeStyles } from '@material-ui/styles';

const styles = (theme) => ({
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  // pill design
  mediaIndicator: {
    fontSize: 18,
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '12px 0',
    '& strong': {
      margin: '1px 0',
    },
    '& span': {
      fontSize: 13,
      opacity: 0.6,
    },
  },
  iconWrapper: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
  },
  mediaIconsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    width: '100%',
    '@media (max-width: 1280px)': {
      // marginTop: 40,
    },
    '& .__react_component_tooltip': {
      width: '300px',
      padding: '8px 12px',
      background: '#545C63',
    },
    '& span.fa-exclamation-circle': {
      fontSize: 17,
    },
  },
  tooltipText: {
    top: '3px',
    right: '2px',
    position: 'absolute',
    margin: '0 0 12px 9px',
  },
  loadingAnimationBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '56px',
    height: '28px',
  },
});
export default styles;
export const useStyles = makeStyles(styles);
