import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {
    TextField,
} from '@material-ui/core';
import { useStyles } from './signUp.styles';
import { Button, Typography } from '../../components/Wrappers';
import LoadingAnimation from '../../components/LoadingAnimation';

// import Widget from '../../components/Widget';

function ConfirmSignUpForm (props) {
    const {
        onConfirmSignUp,
        onResendCode,
        resendIsLoading,
        verifyIsLoading,
    } = props;

    const classes = useStyles();
    const [code, setCode] = useState('');
    const [isLoading, setIsLoading] = useState(verifyIsLoading);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [isResendLoading, setIsResendLoading] = useState(resendIsLoading);
    useEffect(() => {
        setIsLoading(false);
        setBtnDisabled(true);
    }, []);

    useEffect(() => {
        if (code.length === 0) {
            setBtnDisabled(true);
        } else {
            setBtnDisabled(false);
        }
    }, [code]);

    useEffect(() => {
        setIsResendLoading(resendIsLoading);
    }, [resendIsLoading]);

    useEffect(() => {
        setIsLoading(verifyIsLoading);
    }, [verifyIsLoading]);

    function renderButton () {
        if (isLoading) {
            return (<LoadingAnimation />);
        }

        return (
            <Button
                variant="contained"
                color="primary"
                disabled={btnDisabled}
                onClick={() => {
                    console.log('Confirm Account');
                    setIsLoading(true);
                    onConfirmSignUp(code);
                }}
            >
                Verify Account
            </Button>
        );
    }

    function renderResend () {
        if (isResendLoading) {
            return (<LoadingAnimation />);
        }

        return (
            <Button
            color="primary"
            size="large"
            onClick={() => onResendCode()}
            className={classes.linkButton}
            >
            Resend Code
        </Button>
        );
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <div>
                    <Grid item justify={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                        >
                            <Typography
                                variant={'h6'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Verify your email
                            </Typography>
                                    <TextField
                                        id="verificationCode"
                                        label="Verification Code"
                                        placeholder="Verification Code"
                                        name="verificationCode"
                                        value={code}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText="Enter the code we sent to your email"
                                        onChange={(e) => {
                                           setCode(e.target.value.trim());
                                        }}
                                    />
                            <div>
                                <div>
                                        <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                        >
                                           { renderButton()}
                                           { renderResend()}
                                        </Box>
                                </div>
                            </div>
                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

ConfirmSignUpForm.propTypes = {
    onConfirmSignUp: PropTypes.func,
    onResendCode: PropTypes.func,
    resendIsLoading: PropTypes.bool,
    verifyIsLoading: PropTypes.bool,
};

ConfirmSignUpForm.defaultProps = {
    onConfirmSignUp: () => {},
    onResendCode: () => {},
    resendIsLoading: false,
    verifyIsLoading: false,
};

export default ConfirmSignUpForm;
