import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    loadingAnimationBox: ({ width }) => {
      return {
        marginLeft: theme.spacing(4),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: width,
        height: '36.5px',
      };
    },
});

export default styles;
export const useStyles = makeStyles(styles);
