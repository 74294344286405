import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({

    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        minWidth: '400px',
        minHeight: '300px',
        padding: '32px',
    },

    message: {
        textAlign: 'center',
    },
    loadingAnimationBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '48px',
    },

});

export default styles;
export const useStyles = makeStyles(styles);
