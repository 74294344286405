import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { logInfo, scrollToTop } from '../../utils';
import {
    Grid,
    Box,
} from '@material-ui/core';
// import { toast } from 'react-toastify';

import useScheduleNavigation from './useScheduleNavigation';
import { Typography } from '../../components/Wrappers';
// import Notification from '../../components/Notification/Notification';
import Widget from '../../components/Widget';

import useConfirmationDialog from '../../commons/dialog/useConfirmationDialog';

import useStyles from './schedule.styles';
import ProgressBar from '../../commons/progress/Progress';
import ScheduleTable from '../../commons/tables/ScheduleTable';
import {
    useSchedulesStore,
    useUserStore,
    useEnvStore,
} from '../../store/models';

import useStoreModuleDependency from '../hooks/useStoreModuleDependency';
import { useHistory } from 'react-router';
import useProductTour from '../hooks/useProductTour';

function Schedule () {
    const schedulesStore = useSchedulesStore();
    const userStore = useUserStore();
    const envStore = useEnvStore();

    function initStore () {
        schedulesStore.getSchedules({
            user: userStore.user,
            publisher: envStore.publisher,
        });
        scrollToTop();
    }

    useStoreModuleDependency({ initStore });

    // when ever useProductTour use isStateDependent to true, issDataReadyForTheTour is will be needed.
    useProductTour({
        isDataReadyForTheTour: () => {
            // console.log('useProductTour() isDataReadyForTheTour() schedules?: ', schedulesStore.schedulesUnobserved);
            if (!isEmpty(schedulesStore.schedulesUnobserved)) {
                return true;
            }
            return false;
        },
        isStateDependent: true,
        observableState: schedulesStore.schedules,
    });

    const {
        handleAddSchedule,
        handleEventList,
      } = useScheduleNavigation();

    const history = useHistory();
    const classes = useStyles();
    const testFlag = false;

    function deleteSchedule () {
        console.log('Target Active Schedule: ', schedulesStore.scheduleEditTarget);
        schedulesStore.bulkDeleteSchedules({
            schedules: [
                { id: schedulesStore.scheduleEditTarget.id, type: schedulesStore.scheduleEditTarget.selectedData.type || 'fixture', name: schedulesStore.scheduleEditTarget.selectedData.name },
            ],
        });
    }

    const {
        renderDialog: renderDeleteDialog,
        openDialog: openDeleteConfirmation,
    } = useConfirmationDialog({
        title: 'Delete Schedule',
        message: 'Are you sure you want to delete this schedule?',
        confirmLabel: 'Yes',
        cancelLabel: 'Cancel',
        onConfirm: () => {
            deleteSchedule();
        },
    });

    useEffect(() => {
        return () => {
            // unmounting schedule
            schedulesStore.scheduleSearchText = '';
            schedulesStore.resetSearchAndFilters();
        };
    }, []);
    return (
        <React.Fragment>
        <Grid container>
            { testFlag
? <ProgressBar />
            : <Grid item xs={12}>
                <Widget
                    className={classes.widgetContainer}
                    buttonText={'Add'}
                    title="Manage Schedules"
                    intercomTitleTarget='manage-schedules-title'
                    ecalDataIdAddButton='manage-schedules-add-button'
                    handleAdd={(e) => {
                        schedulesStore.resetSearchAndFilters();
                        handleAddSchedule(e);
                    }}

                    handleSecondaryButton={() => {
                        history.push('/admin/schedules/import-events');
                    }}
                    secondaryButtonLabel={'Import Events'}
                    showSecondaryButton={true}

                    inheritHeight disableWidgetMenu>
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        alignItems={'flex-start'}
                    >
                        <Typography variant='body2'>
                            Your events are organised into schedules. Users subscribe to these to keep up-to-date with your events.
                        </Typography>
                    </Box>
                </Widget>
                <Box
                    justifyContent={'space-between'}
                    display={'flex'}
                    alignItems={'flex-start'}
                    marginBottom={'16px'}
                    // marginTop={'32px'}
                >
                    <ScheduleTable
                        statusFilter={schedulesStore.scheduleFilter}
                        intercomTargetManageEvents={'schedule-manage-events'}
                        tableData={schedulesStore.schedules}
                        manageEventClick={handleEventList}
                        currentPage={schedulesStore.schedulePage}
                        totalCount={schedulesStore.schedulesTotalCount}

                        showLoading={schedulesStore.scheduleIsLoading}
                        onPageChange={(page) => {
                            console.log('ON PAGE CHANGE YEY!!!', page);
                            schedulesStore.updateSchedulePage(page);
                        }}

                        onSearch={(value) => {
                            console.log('on Search() ', value);
                            schedulesStore.scheduleSearchText = value;
                            schedulesStore.searchOrGetSchedules();
                        }}

                        onFilter={(value) => {
                            console.log('on Filter() ', value);
                            schedulesStore.scheduleFilter = value;
                            schedulesStore.searchOrGetSchedules();
                            // schedulesStore.scheduleFilter = '';
                        }}

                        onEditSchedule={() => {
                            schedulesStore.resetSearchAndFilters();
                            envStore.history.push('/admin/schedules/edit/' + schedulesStore.scheduleEditTarget.id);
                        }}

                        onDeleteSchedule={() => {
                            openDeleteConfirmation();
                        }}

                        onActionIconClick={(value, data) => {
                            schedulesStore.scheduleEditTarget = {
                                id: value,
                                data: data.rowData,
                            };

                            schedulesStore.scheduleDataFromSelectedItem();
                            schedulesStore.saveEditData();
                        }}

                        onSetItemsLive={(data) => {
                            schedulesStore.makeSchedulesLive(data);
                        }}

                        onSetItemsDraft={(data) => {
                           schedulesStore.makeSchedulesDraft(data);
                        }}

                        onDeleteItems={(data) => {
                            schedulesStore.deleteSchedules(data);
                        }}

                        onManageEventsClick={(value, data) => {
                            logInfo('onManageEventsClick: ID -> ', value);
                            logInfo('onManageEventsClick: Data: ', data);
                            schedulesStore.scheduleEditTarget = {
                                id: value,
                                data: data.rowData,
                            };
                            schedulesStore.scheduleDataFromSelectedItem();
                            schedulesStore.saveEditData();
                            envStore.history.push(`/admin/schedules/${schedulesStore.scheduleEditTarget.id}/events?scheduleName=${schedulesStore.scheduleEditTarget.data[1]}`);
                        }}
                    />
                </Box>
            </Grid>
            }
        </Grid>
        { renderDeleteDialog() }
        </React.Fragment>
    );
}

export default observer(Schedule);
