import React from 'react';
import { useStyles } from './styles';
import LOADING_ANIMATION from '../../commons/images/icons/loadingAnimation.gif';

function LoadingAnimation () {
    const classes = useStyles();

    return (
        <React.Fragment>
            <img
                src={LOADING_ANIMATION}
                alt="loading"
                className={classes.loadingAnimation}
            />
        </React.Fragment>
    );
}

export default LoadingAnimation;
