import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
// import useNavigation from '../hooks/useNavigation';
import { useHistory, useLocation } from 'react-router';
import {
  Grid,
  Box,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ErrorContainer from '../../commons/errorContainer/Error';
import useStyles from './webhook.styles';
import ProgressBar from '../../commons/progress/Progress';
import {
    useWebhooksStore,
    // useUserStore,
    useErrorStore,
    useEnvStore,
} from '../../store/models';
// import useErrorNotification from '../../store/models/error/useErrorNotification';
// import useWebhookErrors from '../../store/models/error/hooks/useWebhookErrors';
import useWebhookErrors from '../../store/models/webhook/errors/useWebhookErrors';
// import Select from '../../commons/select/Select';
import { Button, Typography, Link } from '../../components/Wrappers';
import LoadingAnimation from '../../components/LoadingAnimation';
// import { has } from 'mobx';

// import Widget from '../../components/Widget';

const EditWebhookForm = (props) => {
    // const {
    //     onSignUp,
    // } = props;

    const [webhook, setWebhook] = React.useState({
        url: '',
        secret: '',
        status: 1,
        createdOn: '',
        id: '',
      });

    const [webhookFlag, setWebhookFlag] = useState(false);
    const [errorDialogFlag, setErrorDialogFlag] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const webhooksStore = useWebhooksStore();
    const errorStore = useErrorStore();
    // const userStore = useUserStore();
    const envStore = useEnvStore();
    // const {
    //     hasError,
    //     title,
    //     message,
    //   } = useErrorNotification();
    // const {
    //     gotoWebhooks,
    // } = useNavigation();
    const history = useHistory();
    const { pathname } = useLocation();

    const {
      errors,
      // title,
      // message,
      hasError,
  } = useWebhookErrors({
    input: null,
  });
    useEffect(() => {
        if (webhooksStore.webhooks && webhooksStore.webhooks[0]) {
            const webhooks = webhooksStore.webhooks;
            const path = pathname;
            const splitPath = path.split('/');
            if (splitPath[4] !== 'add') {
                const filteredWebhook = webhooks.filter(webhook => webhook.id === splitPath[5]);
                console.log('webhooksStore.webhooks', filteredWebhook[0]);
                setWebhook(filteredWebhook[0]);
            }
        }

        // setWebhook(webhooksStore.webhooks[0]);
    }, [webhooksStore.webhooks]);

      function handleChange (e, selectedVal) {
        const updatedData = {
            ...webhook,
            [e.target.name]: e.target.value,
        };

        console.log('Updated Data: ', updatedData);
        console.log('Password: ');
        setWebhook(updatedData);
      }
    // const steps = getSteps();
    const classes = useStyles();

    // const submitSignUpForm = () => {
    //     if (typeof onSignUp === 'function') {
    //         // onSignUp(newUser);
    //     }
    // };

    const handleSave = async (e) => {
        e.preventDefault();
        if (webhook.id !== '') {
            const res = await webhooksStore.saveWebhooks({
                body: webhook,
                publisher: envStore.publisher,
                webhookId: webhook.id,
            });
            console.log('res? ', res);
            if (res) {
              history.push('/admin/developers/webhooks');
            }
        } else {
          const res = await webhooksStore.addWebhook({
                body: webhook,
                publisher: envStore.publisher,
            });
            if (res) {
              history.push('/admin/developers/webhooks');
            }
        }
        setWebhookFlag(true);
        // setTimeout(function () {
        //     if (!hasError) {
        //         history.push('/admin/developers/webhooks');
        //     }
        //   }, 0);
         // gotoWebhooks();
    };

    useEffect(() => {
        setErrorDialogFlag(hasError);
        if (webhookFlag && !hasError) {
            history.push('/admin/developers/webhooks');
        }
    }, [errorStore.getWebhookKey]);

    useEffect(() => {
        console.log('url error? ', errors, hasError);
        if (errors.url) {
          setErrorDialogFlag(true);
        }
    }, [errors]);

    const handleOK = () => {
        setErrorDialogFlag(false);
        setIsLoading(false);
        // props.setFlag(true);
        // setActiveStep(prevActiveStep => prevActiveStep - 1);
    };
    // const handleBack = () => {
        // props.setFlag(true);
        // setActiveStep(prevActiveStep => prevActiveStep - 1);
    // };
    console.log('webhooksStore.webhook_progressBar ', webhooksStore.webhook_progressBar);
    return (
        <Grid container spacing={3}>
            {hasError
            ? <ErrorContainer
                title={errors.title}
                dialogContextText={errors.message || errors.url}
                openDialog={errorDialogFlag}
                handleAgree={handleOK}
            />
            : null}
            <Grid item xs={12}>
                <div>
                    <Grid item justify={'center'} container>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            width={600}
                            className={classes.boxStyling}
                        >
                            <Typography
                                variant={'h5'}
                                weight={'medium'}
                                style={{ marginBottom: 30 }}
                            >
                                Webhook
                            </Typography>
                            { webhooksStore.webhook_progressBar
                                ? <ProgressBar />
                                : <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    width={'100%'}>
                                    <TextField
                                      error={hasError}
                                        id="url"
                                        label="Callback URL"
                                        onChange={handleChange}
                                        name="url"
                                        value={webhook.url || ''}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText={ hasError
? (errors.message || errors.url)
: (
                                          'Notification will be sent to this URL when a user subscribes to your ECAL service'
                                        )
                                          }
                                    />
                                    <TextField
                                        id="secret"
                                        label="Encryption Secret Key (optional)"
                                        onChange={handleChange}
                                        name="secret"
                                        value={webhook.secret || ''}
                                        variant="outlined"
                                        style={{ marginBottom: 35 }}
                                        helperText="If set, ECAL will use this key to encrypt the payload sent to this Webhook"
                                    />
                                        <Box
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                        >
                                            <Button
                                                onClick= { (e) => {
                                                    e.preventDefault();
                                                    history.push('/admin/developers/webhooks');
                                                }}
                                                variant={'outlined'}
                                                color={'primary'}
                                            >
                                                <Link
                                                // href={'/admin/developers/webhooks'}
                                                color='#fff'>
                                                {/* <Link onClick={handleClose} color='#fff'> */}
                                                  close
                                                </Link>
                                            </Button>
                                            {isLoading
? (
                  <LoadingAnimation />
                )
: (
    <Button
                                                variant="contained"
                                                color="primary"
                                                // onClick={handleSave}
                                                onClick={async (e) => {
                                                    setIsLoading(true);
                                                    handleSave(e);
                                                }}
                                            > Save
                                            </Button>

                )}
                                        </Box>
                                </Box>}

                        </Box>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default observer(EditWebhookForm);
