import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import { Button } from '../../components/Wrappers';
import LoadingAnimation from '../../components/LoadingAnimation';
import { useStyles } from './styles';

function ProgressButton (props) {
    const {
        showProgress,
        onClick,
        label,
        disabled,
        variant,
        dataIntercomTarget,
        width = '70px',
    } = props;

    const classes = useStyles({ width });
    const [loading, setLoading] = useState(showProgress);

    useEffect(() => {
        setLoading(showProgress);
    }, [showProgress]);

    if (loading) {
        return (<Box className={classes.loadingAnimationBox}><LoadingAnimation /></Box>);
    }

    return (
        <Button
            disabled={disabled}
            variant={variant}
            color="primary"
            onClick={(e) => {
                if (typeof onClick === 'function') {
                    onClick(e);
                }
            }}
            data-intercom-target={dataIntercomTarget}
        >
            {label}
        </Button>
    );
}

ProgressButton.propTypes = {
    showProgress: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.string,
    variant: PropTypes.string,
    width: PropTypes.string,
};

ProgressButton.defaultProps = {
    showProgress: false,
    disabled: false,
    onClick: () => {},
    label: 'Button',
    variant: 'contained',
    width: '70px',
};

export default ProgressButton;
