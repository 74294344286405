import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Dialog from '../../../components/Dialog';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import {
    Grid,
    TextField as Input,
  } from '@material-ui/core';

import { Button, Typography } from '../../../components/Wrappers/Wrappers';
import { useStyles } from './loginOTP.styles';
import { useUserStore, useEnvStore } from '../../../store/models';
import { SignInSignUpState } from '../../../utils/constants';
import useOTPError from './useOTPError';
import LoadingAnimation from '../../../components/LoadingAnimation';

function LoginOTP (props) {
    const {
        resendOTP,
        onOTPChange,
        onVerify,
        onResendOTP,
        qrCode,
        ...otherProps
    } = props;
    const userStore = useUserStore();
    const envStore = useEnvStore();

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [input, setInput] = useState({ otp: '' });

    /*
           error={error.code}
                            helper={error.code || 'Enter verification code.'}
    */

   const { error } = useOTPError({ input });

    function renderWelcomeText () {
        if (qrCode) {
            return 'Please scan the QR code with Authy or Google Authenticator to setup security and retrieve your one-time password.';
        }
        return 'Please enter your OTP code.';
    }

    function renderButton () {
        if (isLoading) {
            return (<LoadingAnimation />);
        }

        return (
            <Button
                onClick={() => {
                    setIsLoading(true);
                    onVerify(input.otp);
                }}
                variant="contained"
                color="primary"
                size="large"
                disabled={error.disableSend}
            >
               Verify OTP
            </Button>
        );
    }

    function updateLoading () {
        const loadingStates = [
           SignInSignUpState.OTP_RESENDING,
           SignInSignUpState.VERIFYING_CREDENTIALS,
        ];
        if (loadingStates.includes(envStore.signInSignUpState.status)) {
          setIsLoading(true);
        }
       setIsLoading(false);
    }

    useEffect(() => {
        updateLoading();
    }, [envStore.signInSignUpState.status]);

    return (
        <Dialog
            PaperProps={{
                classes: {
                  root: classes.dialogContainer,
                },
            }}
            {...otherProps}
        >
            <Grid className={classes.content}>
                <Grid xs={12} lg={12}>
                    <Grid item container className={classNames([classes.bottomSpace])}>
                        <Typography variant={'body1'} className={classes.welcomeText}>
                            {renderWelcomeText()}
                        </Typography>
                    </Grid>

                    { qrCode && (
                        <Grid>
                            <QRCode value={qrCode}/>
                        </Grid>
                    )
                    }

                    <Grid item container className={classNames([classes.bottomSpace])}>
                        <Input
                            type='number'
                            inputProps={{ max: 10 }}
                            name='OTP'
                            value={input.otp}
                            onInput = {(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
                            }}
                            onChange={(e) => {
                                setInput({ otp: e.target.value });
                                onOTPChange(e.target.value);
                            }}
                            error={error.code}
                            helperText={error.code || 'Enter verification code.'}
                        />
                    </Grid>

                    <Grid item container className={classNames([classes.buttonSpace])}>
                        <Button
                            onClick={() => {
                                setIsLoading(true);
                                userStore.resendForOTP();
                            }}
                            size="large"
                            color="primary"
                            className={classes.textButton}
                        >
                            Resend OTP
                        </Button>

                        {renderButton()}
                    </Grid>
                </Grid>
            </Grid>
    </Dialog>);
}

LoginOTP.propTypes = {
    onOTPChange: PropTypes.func,
    onVerify: PropTypes.func,
    onResendOTP: PropTypes.func,
    open: PropTypes.bool,
    resendOTP: PropTypes.bool,
    qrCode: PropTypes.string,
};

LoginOTP.defaultProps = {
    onOTPChange: () => {},
    onVerify: () => {},
    onResendOTP: () => {},
    open: false,
    resendOTP: false,
    qrCode: '',
};
export default observer(LoginOTP);
