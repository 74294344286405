import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Grid,
  Link,
} from '@material-ui/core';

// styles
import { useStyles } from './signUp.styles';

// import EcalLogoImage from '../../commons/images/brandImage/ECALWhiteLabel2.jpg';

import google from '../../commons/images/google.svg';

// components
import { Button, Typography } from '../../components/Wrappers/Wrappers';
import SignUpForm from './signUpForm';
import { useUserStore } from '../../store/models';
import useOpenSignIn from '../hooks/useOpenSignin';
import useEnvHistory from '../hooks/useEnvHistory';
import useWhiteLabel from '../../store/models/styles/useWhiteLabel';
import useNavigation from '../hooks/useNavigation';

function SignUp (props) {
  const {
    whiteLabelSettings,
  } = useWhiteLabel();

  const classes = useStyles({ whiteLabelSettings });

  const userStore = useUserStore();
  useEnvHistory();
  const [googleFlow, setGoogleFlag] = useState(true);

  const {
    signInWithGoogle,
  } = useOpenSignIn({ whiteLabelSettings });

  const setGoogleFlagFunc = (flag) => {
    setGoogleFlag(flag);
  };

  const {
    gotoLogIn,
  } = useNavigation();

  return (
    <Grid container className={classes.container}>
      <div className={classes.formDividerContainer}></div>
      <div className={classes.logotypeContainer}>
        <img src={whiteLabelSettings.background} alt="logo" className={classes.logotypeImage} />
      </div>

      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Grid item container className={classes.logoImageContainer}>
              <div id='logoImage' className={classes.logoImage}></div>
            </Grid>
            {googleFlow && (
              <Grid className={classes.headerSpace}>
                <Typography variant={'body1'} className={classes.headerTextTitle}>
                  Do you have events?
                </Typography>
                <Typography variant={'body2'} className={classes.headerText}>
                  Create an ECAL account in seconds, so you can communicate events better!
                </Typography>
              </Grid>
              )
            }
            {googleFlow
              ? <div>
                <Button
                  size="large"
                  className={classes.googleButton}
                  onClick={() => {
                       signInWithGoogle();
                  }}
                >
                  <img src={google} alt="google" className={classes.googleIcon} />
                  &nbsp;Sign Up with Google
                </Button>
                <div className={classes.formDividerContainer}>
                  <div className={classes.formDivider} />
                    <Typography className={classes.formDividerWord}>or</Typography>
                  <div className={classes.formDivider} />
                </div>
              </div>
              : <div className={classes.formDividerContainer}></div>
            }

            <SignUpForm
              setFlag={setGoogleFlagFunc}
              onSignUp={(data) => {
                console.log('New User : ', data);
                userStore.signUp(data);
              }}

              onInsertLead={(user) => {
                userStore.insertLead({
                  ...user,
                });
              }}

              />
            {googleFlow
              ? <div className={classes.logInSentence}>
              <Typography className={classes.logInText}>
              Have an account already?
              </Typography>
              <Link
                onClick={() => gotoLogIn()}
                className={classes.logInLink}
              >
                LOG IN
              </Link>
            </div>
              : <div></div>
            }
          </React.Fragment>

        </div>
        <Typography variant={'body2'} color="primary" className={classes.copyright}>
            { whiteLabelSettings.copyrightText }
        </Typography>
      </div>
    </Grid>
  );
}

export default observer(SignUp);
