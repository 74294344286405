
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { RecoveryState } from '../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import {
  InputAdornment,
  TextField as Input,
  Box,
} from '@material-ui/core';

// styles
import { useStyles } from './login.styles';

// components
import LoadingAnimation from '../../components/LoadingAnimation';
import { Button, Typography } from '../../components/Wrappers';
import { useUserStore, useEnvStore } from '../../store/models';
import useForgotPasswordError from './useForgotPasswordError';
/*
 * Send Email Component
 */
function ForgotEmail (props) {
  const {
    onSendRecovery,
    onBackToLogin,
    isLoading,
    setIsLoading,
  } = props;

  const classes = useStyles();
  const [forgotEmail, setForgotEmail] = useState('');
  const { error } = useForgotPasswordError({ input: null });

  return (
    <div className={classes.form}>
      <Typography variant={'body1'} className={classes.welcomeText}>
        Enter your email to receive your recovery code
      </Typography>
      <Input
        id="email_forgot"
        InputProps={{
          classes: {
            underline: classes.InputUnderline,
            input: classes.Input,
          },
        }}
        value={forgotEmail}
        onChange={e => setForgotEmail(e.target.value)}
        margin="normal"
        placeholder="Email"
        type="Email"
        error={error.emailVerify}
        helperText={ error.emailVerify || ' ' }
        fullWidth
      />

      <Typography className={classes.noteText}>
        Note: If you created your account using &lsquo;Sign in with Google&rsquo;, you cannot recover your password using this method.
      </Typography>
      <div className={classes.formButtons}>
        {isLoading
? (
          <LoadingAnimation />
        )
: (
          <Button
            disabled={
              forgotEmail.length === 0
            }
            onClick={() => {
              if (typeof onSendRecovery === 'function') {
                setIsLoading(true);
                onSendRecovery(forgotEmail);
              }
            }}

            variant="contained"
            color="primary"
            size="large"
          >
            Send
          </Button>
        )}
        <Button
          color="primary"
          size="large"
          onClick={() => onBackToLogin()}
          className={classes.forgetButton}
        >
          Back to login
        </Button>
      </div>

  </div>
  );
}

/*
 * Recovery Form Component with Change Password
 */
function RecoverCode (props) {
  const {
    onSendCode,
    onBackToLogin,
    onResendCode,
    isLoading,
    setIsLoading,
  } = props;

  const classes = useStyles();
  const [code, setCode] = useState('');
  const [pass, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [input, setInput] = useState({ code, password: pass });

  const { error } = useForgotPasswordError({ input });

  useEffect(() => {
    setInput({
      code,
      password: pass,
    });
    // validateInputs();
  }, [code, pass]);

  useEffect(() => {
    // validateInputs();
  }, []);

  useEffect(() => {
    setIsLoading(false);
    // validateInputs();
  }, [error]);

  return (
    <Box
      ml={4} mr={4}
      className={classes.forgotPasswordBox}
    >
      <Box textAlign={'left'} >
        <Typography variant={'h5'} className={classes.recoveryText}>
          Verify your email with your recovery code
        </Typography>
        <Typography weight='light' variant={'body2'} className={classes.recoveryText}>
          If you have a registered email, a recovery code has been sent to your inbox. Please enter the code below, and set a new password.
        </Typography>
      </Box>

      <Input
        type='number'
        name='verificationCode'
        InputProps={{
          classes: {
            underline: classes.InputUnderline,
            input: classes.Input,
          },

        }}
        value={code}
        onChange={e => setCode(e.target.value)}
        margin="normal"
        placeholder="Code"
        inputProps={{ max: 10 }}

        onInput = {(e) => {
            e.target.value = e.target.value.slice(0, 6);
            // e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6);
        }}

        error={ error.code }
        helperText={
          error.code || 'Enter recovery code'
        }

        fullWidth
        autoComplete="off"
      />

      <Input
        id="password"
        type={showPassword ? 'Text' : 'Password'}
        name="newPass"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
                  <Box onClick={() => {
                      setShowPassword(!showPassword);
                  }}>
                    <FontAwesomeIcon icon={ showPassword ? faEye : faEyeSlash } />
                  </Box>
            </InputAdornment>
          ),

          classes: {
            underline: classes.InputUnderline,
            input: classes.Input,
          },
          autoComplete: 'new-password',
        }}
        value={pass}
        onChange={e => setPassword(e.target.value)}
        margin="normal"
        placeholder="New Password"
        error={ error.password }
        helperText={
          error.password || 'Enter password'
        }
        fullWidth
      />

      <div className={classes.formButtons}>
        {isLoading
? (
          <LoadingAnimation />
        )
: (
          <Button
            disabled={error.disableSend}
            onClick={() => {
              if (typeof onSendCode === 'function') {
                setIsLoading(true);
                onSendCode({ code, password: pass });
              }
            }}

            variant="contained"
            color="primary"
            size="large"
          >
            Verify Code
          </Button>
        )}

        <Button
          color="primary"
          size="large"
          onClick={() => onResendCode()}
          className={classes.forgetButton}
        >
          Resend Recovery Code
        </Button>

        <Button
          color="primary"
          size="large"
          onClick={() => onBackToLogin()}
          className={classes.forgetButton}
        >
          Back to login
        </Button>
      </div>
  </Box>
  );
}

/*
 * Main LoginForgot Components
 */
const RecoverCodeObserver = observer(RecoverCode);
const ForgotEmailObserver = observer(ForgotEmail);
function LoginForgot (props) {
  const {
    onBackToLogin,
    isLoading,
    setIsLoading,
  } = props;

  const Steps = {
    SEND_CODE_TO_EMAIL: 'sendToEmail',
    VERIFY_CODE: 'verifyCode',
  };

  const userStore = useUserStore();
  const envStore = useEnvStore();

  const classes = useStyles();

  const [step, setStep] = useState(Steps.SEND_CODE_TO_EMAIL);

  useEffect(() => {
    const step2States = [
      RecoveryState.RECOVERY_CODE_SENT,
    ];

    if (step2States.includes(envStore.recoveryState.status)) {
      setStep(Steps.VERIFY_CODE);
    } else {
      setStep(Steps.SEND_CODE_TO_EMAIL);
    }
  }, [envStore.recoveryState.status]);

  useEffect(() => {
    envStore.recoveryState = {
      status: RecoveryState.INITIAL_STEP,
      // status: RecoveryState.RECOVERY_CODE_SENT, // use this to test step 2
    };
  }, []);

  function renderStep () {
    if (step === Steps.VERIFY_CODE) {
        return (
          <RecoverCodeObserver
            onBackToLogin={onBackToLogin}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onSendCode={({ code, password }) => {
              console.log('will send code: ', code);
              console.log('will send password: ', password);
              userStore.recoverPassword({ code, password });
            }}

            onResendCode={() => {
              envStore.recoveryState = { status: RecoveryState.INITIAL_STEP };
            }}
          />
        );
      }
    return (
      <ForgotEmailObserver
         isLoading={isLoading}
         setIsLoading={setIsLoading}
          onSendRecovery={(email) => {
            userStore.forgotPassword({ email: email });
          }}
          onBackToLogin={onBackToLogin}
      />
    );
  }

  return (
      <div className={classes.customFormContainer}>
        {renderStep()}
      </div>
  );
}

LoginForgot.propTypes = {
  onBackToLogin: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
};

LoginForgot.defaultProps = {
  onBackToLogin: () => {},
  setIsLoading: () => {},
  isLoading: false,
};

export default observer(LoginForgot);
