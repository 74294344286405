import React, { useEffect, useRef } from 'react';
import MuiDialog from '@material-ui/core/Dialog';
import { Box } from '@material-ui/core';
import { useStyles } from './progress.styles';
import LoadingAnimation from '../LoadingAnimation';

function Progress (props) {
    const {
        open,
        children,
        title,
        onClose,
        disableBackdropDoubleClick,
        ...otherProps
    } = props;
    const classes = useStyles();
    const dialogRef = useRef(null);

    useEffect(() => {
        if (open === true) {
            setTimeout(() => {
                if (dialogRef && dialogRef.current) {
                    dialogRef.current.ondblclick = (e) => {
                        if (disableBackdropDoubleClick) return;
                        if (!e.srcElement) return;
                        if (!e.srcElement.className) return;
                        const className = e.srcElement.className;
                        if (typeof className.indexOf === 'function' &&
                             className.indexOf('MuiDialog-scrollPaper') !== -1) {
                                if (typeof onClose === 'function') {
                                    onClose();
                                }
                        }
                    };
                }
            }, 1000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <MuiDialog
            ref={dialogRef}
            disableBackdropClick={true}
            open={open}
            onClose={onClose}

            classes={{
                root: classes.root,
            }}

            {...otherProps}
        >
             <Box
                mt={2}
                mr={2}
                ml={2}
                mb={2}
                className={classes.progressBox}
             >
                <LoadingAnimation />
              </Box>
        </MuiDialog>
    );
}

Progress.defaultProps = {
    title: 'Dialog Title',
    onClose: () => {},
};

export default Progress;
