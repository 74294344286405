import { v4 as uuid } from 'uuid';
import { isExpired, decodeToken } from 'react-jwt';
import { isEmpty } from 'lodash';
import { Auth } from 'aws-amplify';
import EcalProfileLogo from '../../../commons/images/brandImage/ecal_icon_purple_light.svg';
import { ecalLogo } from '../../../commons/images/brandImage/ecalLogo';
import {
    logInfo,
    getEnvInfo,
} from '../../../utils';
import axios from 'axios';

/*
import {
    getEnvInfo,
} from '../../../utils';
*/

import {
    apiAuthGetPublisherInfo,
    apiSearch,
    apiUpdateProfile,
    apiUploadPublisherAssets,
    apiGenerateSVG,
    apiPublisherLogo,
    apiDeletePublisherLogo,
    apiInsertLead,
} from './api';

export async function authGetPublisherInfo ({ cognitoToken, navigate = true, updateUserInfoLoaded = true, setupForPlan = false }) {
    this.env.errorStore.action = 'authGetPublisherInfo';
    /*
     * in pub admin case
     * env.publisher will contain the publisher info pulled auth/publisher.
     *
     * in super admin case, env.publisher will come from the selected publisher from publisher's list
     *
     */
    logInfo('UserStore.authGetPublisherInfo()');
    if (this.verifySessionExpired()) return;

    const res = await apiAuthGetPublisherInfo({ cognitoToken }, { traceId: this.env.traceId });
    if (res.data && res.data.publishers) {
        const publishers = res.data.publishers;

        logInfo('UserStore.authGetPublisherInfo()', publishers);

        if (publishers.length > 0) {
            if (this.isSuperAdmin) {
                const token = publishers[0].token;
                this.superAdminToken = token;
                // logInfo('UserStore.authGetPublisherInfo() Super ADMIN TOKEN ->', this.superAdminToken);
                this.superAdmin = publishers[0];
                this.userToken = publishers[0].token;

                this.user = {
                    ...this.user,
                    token: this.superAdminToken,
                };

                this.observed.user = uuid();
                await this.reloadSelectedPublisher();
            } else {
                this.managePublisher(publishers[0]);
            }

            if (this.publisher && !this.env.awsMarketplaceToken) {
                await this.getPublisherLogo();
            }

            await this.processAWSMarketplaceBilling();

            if (navigate) {
                // only navigate to a particular page when navigate is true, default = true
                this.navigateInitPageByUser(publishers[0], setupForPlan);
            }

            this.notifyForUserReady({ updateUserInfoLoaded });
        }
    }
}

export function notifyForUserReady ({ updateUserInfoLoaded = true }) {
    this.env.closeProgress();
    if (updateUserInfoLoaded) {
        this.userInfoLoaded = true;
        this.observed.userInfoLoaded = uuid();
    }
}

export async function searchPublishers ({ name = '', publisherIds = [] }) {
    if (!this.superAdmin) {
        return;
    }

    this.recentSearch = name;
    this.env.showProgress();
    this.env.errorStore.gqlAction = 'searchPublishers';
    const res = await apiSearch({
        name,
        publisherIds,
        token: this.user.token,
        traceId: this.env.traceId,
        page: this.publishersPageInfo.page + 1,
    });
    this.env.closeProgress();
    let data = {};
    if (res.data.data) {
        data = res.data.data;
    }

    if (data &&
        data.searchPublishers &&
        data.searchPublishers.edges) {
        const publishers = data.searchPublishers.edges.map((edge) => {
            const pubData = edge.node;
            const payload = decodeToken(pubData.token);

            return {
                ...pubData,
                email: payload.email,
                publisherId: payload.publisherId,
                orgId: payload.orgId,
            };
        });

        this.updatePageInfoFromSearch(data);
        // console.log('user.actions.searchPublishers() Publishers: ', publishers);
        this.publishers = [
            ...publishers,
        ];

        this.observed.publishers = uuid();
    }
}

function sanitizeUser (user) {
    const sanitized = {
        firstName: user.firstName,
        lastName: user.lastName,
        company: user.company,
        industry: user.industry,
        objective: user.objective,
        timezone: user.timezone,
        phone: user.phoneNumber,
        email: user.email,
    };

    return sanitized;
}

export async function updateProfile ({ user, orgSettings }, isSetup = false) {
    /* const info = getEnvInfo(); */
    const publisher = this.publisher;
    const loggedInUser = this.user;
    let token = orgSettings ? publisher.token : loggedInUser.token;

    if (!token && isSetup) {
        token = publisher.token || loggedInUser.token;
    }

    const nonExisitingCognitoUserId = uuid(); // this will prevent the update from super admin to update cognito...
    const aws = {
        ...this.aws,
        cognitoUsername: orgSettings ? nonExisitingCognitoUserId : this.aws.cognitoUsername,
    };

    const sanitizedUser = sanitizeUser(user);

    logInfo('Sanitzed User Params: ', sanitizedUser);
    logInfo('Token: ', token);

    const res = await apiUpdateProfile({
        user: {
            cognitoUserName: aws.cognitoUsername,
            cognitoPoolId: aws.cognitoUserPoolId,
            isNewlyCreated: isSetup,
            ...sanitizedUser,
        },
        token: token,
        traceId: this.env.traceId,
    });

    if (res.data.success) {
        logInfo('updateProfile() will reloadSelectedPublisher');
        this.reloadSelectedPublisher();
    }
    logInfo('Updated Publisher -> ', this.publisher);
    this.observed.publisher = uuid();
    this.observed.user = uuid();
    if (isSetup) {
        console.log('will finalize user setup');
        /* notify cognito to complete the user setup... */
       this.completeUserSetup();
    }
    console.log('res: ', res);
}

export async function uploadAssets (props) {
    const publisher = this.env.userStore.publisher;
    const formData = new FormData();
    formData.append('assetType', props.assetType);
    formData.append('file', props.file);

    const res = await apiUploadPublisherAssets({
        data: formData,
        token: publisher.token,
        traceId: this.env.traceId,
    });
    return res;
}

export async function uploadPublisherAssets (props) {
    const res = await this.uploadAssets(props);
    const { hasError } = this.env.errorStore.handlePublisherError({
        response: res,
        action: 'uploadAssets',
        type: props.assetType,
    });

    if (hasError) {
        return;
    }
    console.log('Response: ', res.data.data);
    this.publisherAsset = res.data.data;
    if (props.assetType === 'publisherLogo') {
        return res;
    }

    this.env.schedulesStore.logoUrl = res.data.data.fileUrl;
    this.calendarLogo = [{ data_url: res.data.data.fileUrl }];
}

export async function generateSVG (params) {
    const publisher = this.env.userStore.publisher;
    const body = {
        buttonColour: params.buttonColour || '#d20b0b',
        fontColour: params.fontColour || '#FFFFFF',
        text: params.text || 'Sync to Calendar',
        width: params.width || 250,
        template: params.template || 'ecal-round',
        theme: params.theme,
        textMatrix: params.textMatrix,
    };

    const res = await apiGenerateSVG({
        data: body,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    return res.data;
}

export async function renewPublisher () {
    if (this.isSuperAdmin && this.publisher) {
        await this.searchPublishers({ name: this.publisher.name });
        if (!isEmpty(this.publishers)) {
            const filtered = this.publishers.filter((data) => {
                return this.publisher.name === data.name;
            });

            if (!isEmpty(filtered)) {
                this.selectPublisher(filtered[0], false);
            }
        }
        this.autoRenewPublisher();
    }
}

/*
 * this method will be used to reload publisher info direct from the server and then persist it locally
 * this will be helpfull on the state of publisher moving forward...
 */
export async function reloadSelectedPublisher () {
    const { publisher } = getEnvInfo();
    logInfo('userStore.reloadSelectedPubsliher()...', publisher);
    if (publisher) {
        this.env.showProgress();
        if (!this.isSuperAdmin) {
            this.authGetPublisherInfo({
                cognitoToken: this.env.userCognitoToken,
                navigate: false,
                updateUserInfoLoaded: true,
                setupForPlan: false,
            });
            return;
        }

        /*
        let searchQuery = `${publisher.publisherId}`;

        if (!searchQuery) {
            searchQuery = publisher.email || publisher.name;
        }

        console.log('reloadSelectedPublisher() -> ', searchQuery);
        await this.searchPublishers({ name: searchQuery });
        console.log('Search Publishers Result -> ', this.publishers);
        if (isEmpty(this.publishers)) {
            // logInfo('userStore.reloadSelectedPubsliher() empty publishers ->', this.publishers);
            this.env.closeProgress();
            return;
        }
        let target = {};
        for (let i = 0; i < this.publishers.length; i++) {
            const p = this.publishers[i];
            if (p.publisherId === publisher.publisherId) {
                target = {
                    ...p,
                };
                break;
            }
        }
        logInfo('userStore.action.reloadSelectedPublisher(): ', target);
        this.selectPublisher(target, false);
        */
        const selectedPublisher = await this.env.publishersStore.getPublisherById(publisher.publisherId);
        if (selectedPublisher) {
            this.selectPublisher(selectedPublisher, false);
        }
        this.env.closeProgress();
    }
}

export function autoRenewPublisher () {
    clearTimeout(window.ecalAutoRenewPubTimeout);
    const thisContext = this;
    function renewNow () {
        thisContext.renewPublisher();
    }
    window.ecalAutoRenewPubTimeout = setTimeout(renewNow, 1000 * 60 * 10); // 10 minutes
    // window.ecalAutoRenewPubTimeout = setTimeout(renewNow, 1000 * 10); // test
}

export async function getPublisherLogo (params = { logoType: 'logo-120' }) {
    this.env.errorStore.action = 'getPublisherLogo';
    if (!this.publisher) return;
    if (isExpired(this.publisher.token)) return;
    const publisher = this.publisher;
    const body = { ...params };
    const res = await apiPublisherLogo({
        data: body,
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (isEmpty(res) || (res.data.errors && res.data.errors.length > 0)) {
        console.log('getPublisherLogo will use the default Logo:');
        this.publisherLogo = EcalProfileLogo;
        this.observed.publisherLogo = uuid();
        return;
    }

    if (res.data.data) {
        const data = res.data.data;
        // use ecal logo when content is empty
        this.publisherLogo = {
            ...data,
            content: data.content || ecalLogo,
        };
        this.observed.publisherLogo = uuid();
    }
    return res.data;
}

export async function uploadProfileLogo (props) {
    this.env.errorStore.action = 'uploadProfileLogo';
    console.log('Props File: ', props.file);
    const res = await this.uploadPublisherAssets({
        file: props.file,
        assetType: 'publisherLogo',
    });
    logInfo('uploadProfileLogo() profile Logo uploaded: ', res);
    if (res.data.data) {
        this.publisherLogoEditable = res.data.data;
        this.observed.publisherLogoEditable = uuid();
    }
    return res;
}

export async function deletePublisherLogo () {
    this.publisherLogoEditable = {
        fileUrl: ecalLogo,
    };
    this.observed.publisherLogoEditable = uuid();

    this.env.errorStore.action = 'deletePublisherLogo';
    if (!this.publisher) return;
    const publisher = this.publisher;
    const res = await apiDeletePublisherLogo({
        token: publisher.token,
        traceId: this.env.traceId,
    });

    if (res.data.data) {
        const data = res.data.data;
        this.publisherLogoEditable = {
            ...data,
            fileUrl: ecalLogo,
        };
        this.observed.publisherLogoEditable = uuid();
    }
}

// * middleware that will refresh the token when it expired
// * it will wait and refresh...
// * there are cases where it will be logged out.

export async function refreshToken () {
    if (this.verifySessionExpired()) return;
    // refresh cognito token...
    let session;
    try {
       session = await Auth.currentSession();
    } catch (err) {
        // it will only happen when no user is involve..
        // this.env.history.push('/login');
        logInfo('refreshToken() will dispatch SessionError from refresh token because congito had expired');
        this.env.errorStore.dispatchSessionError();
        return;
    }
    // get the current authenticaed user
    if (session && session.idToken) {
        await Auth.currentAuthenticatedUser();
        const res = this.manageCognitoPayload(session.idToken.payload);

        // when cognito has auth issue
        if (res && res.hasError) {
            // this.env.history.push('/login');
            logInfo('refreshToken() will dispatch SessionError from refresh token cognito payload had errors');
            this.env.errorStore.dispatchSessionError();
            return;
        }

        this.env.userCognitoToken = session.idToken.jwtToken;
        logInfo('user.action.refreshToken() -> invoking authGet-PublisherInfo()');
        await this.authGetPublisherInfo({
            cognitoToken: session.idToken.jwtToken,
            navigate: false,
            updateUserInfoLoaded: true,
        });
        if (this.isSuperAdmin) {
            // call the search and pull the latest publisher information...
            // make the search publisher call
            // await this.searchPublishers({ name: this.publisher.name });
            await this.searchPublishers({ publisherIds: [this.publisher.publisherId] });
            if (!this.publishers) {
                this.env.closeProgress();
                return;
            }

            const specificPublihser = this.publishers.filter((data) => {
                return this.publisher.publisherId === data.publisherId;
            })[0];
            this.selectPublisher(specificPublihser, false);
        }
    }
}

// Remove this spare function in March
// export async function getIP () {
//     const res = await axios.get('https://geolocation-db.com/json/');
//     this.IP = res.data.IPv4;
// }

export async function getCountryInfo () {
    let res = {};
    try {
        res = await axios.get('https://ipapi.co/json/');
    } catch (error) {
        // console.error(error);
    }

    this.countryInfo = res?.data;
    this.countryInfo.timezone = this.publisher?.timezone || res?.data.timezone;
    this.IP = res.data.ip;
}

export async function insertLead (params) {
    this.env.errorStore.action = 'insertLead';

    const {
        email,
        lastName,
    } = params;

    let source = 'Web Free Trial';
    if (this.env.settings.plan) {
        source = `Web ${this.env.settings.plan}`;
    }

    let firstName = params.firstName;

    if (window.env !== 'prod') {
        firstName = window.env + firstName;
    }

    const body = {
        email,
        firstName,
        lastName,
        source,
    };

    if (this.env.awsMarketplaceToken) {
        body.source = 'AWS Marketplace';
    }

    if (this.env.settings.source === 'ms') {
        body.source = 'Microsoft Marketplace';
    }

    if (this.env.settings.gclid) {
        body.gclid = this.env.settings.gclid;
        body.source = `${body.source} - Google AdWords`;
    }

    console.log('insertLead() -> ', body);
    const res = await apiInsertLead({
        data: body,
        traceId: this.env.traceId,
    });
    console.log('insertLead():', res);
}
